import React from 'react';
import { useState, useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import MFDLogo from '../img/MFD_logo_2_trans.png';
import CenterLogo from '../img/Center_Logo_Name.png';
import CenterLogoHome from '../img/Center_Logo_Name_Home.png';

function NavBar() {
    const [displayMenu, setDisplayMenu] = useState(false)
    const [displayServicesSubmenu, setDisplayServicesSubmenu] = useState(false)
    const [width, setWidth] = useState(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)
    const isHomepage = window.location.href.indexOf("home") > -1
    const navLinkClass = isHomepage ? "navigation-link-home" : "navigation-link"

    const mobileSize = (width <= 1153)

    const toggleMenu = () => {
        setDisplayMenu(!displayMenu);
    }

    const hideMenu = () => {
        setDisplayMenu(false);
    }

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)
        }

        window.addEventListener('resize', handleResize)

        return _ => { window.removeEventListener('resize', handleResize) }
    })

    return (
        <>
            <header className={isHomepage ? "App-header-home" : "App-header"}>
                <NavLink exact to="/home">
                    <img src={MFDLogo} alt="Maker's Favourite Designs" className="header-logo" />
                </NavLink>
                <nav>
                    <div className="collapse-button-area">
                        <div className="col-12 d-flex justify-content-center">
                            <NavLink className="big-center" exact to="/home" onClick={() => hideMenu()}>
                                <img src={isHomepage ? CenterLogoHome : CenterLogo} alt="Maker's Favourite Designs" className="header-center-logo" />
                            </NavLink>
                        </div>
                        <button className="collapse-button" onClick={() => toggleMenu()}>
                            <FontAwesomeIcon icon={faBars} size="2x" />
                        </button>
                    </div>
                    {(mobileSize) ?
                        <div id="navMenu" className={displayMenu ? "col-12 expand-menu" : "col-12 collapse-menu"}>
                            <div className="col-12 d-flex justify-content-center">
                                <NavLink className={navLinkClass} to="/about" onClick={() => toggleMenu()}>About</NavLink>
                            </div>
                            <div className="col-12 d-flex justify-content-center">
                                <NavLink className={`${navLinkClass} nav-services`} to="/services" onClick={() => toggleMenu()} style={{ marginLeft: '10px'}}>
                                    Services
                                    <span style={{ padding: '10px' }} onClick={(e) => {e.preventDefault(); e.stopPropagation(); setDisplayServicesSubmenu(!displayServicesSubmenu) }}>
                                        {displayServicesSubmenu ? <FontAwesomeIcon icon={faCaretUp} size="1x" /> : <FontAwesomeIcon icon={faCaretDown} size="1x" />}
                                    </span>
                                </NavLink>
                            </div>
                            <CSSTransition in={displayServicesSubmenu} classNames="submenu-animate" timeout={1000} unmountOnExit>
                                <div className="services-submenu-container">
                                    <NavLink className="submenu-link" to="/eventcalligraphy" onClick={() => toggleMenu()}>Live Events</NavLink>
                                    <NavLink className="submenu-link" to="/engraving" onClick={() => toggleMenu()}>Engraving</NavLink>
                                    <NavLink className="submenu-link" to="/weddingcalligraphy" onClick={() => toggleMenu()}>Weddings</NavLink>
                                </div>
                            </CSSTransition>
                            <div className="col-12 d-flex justify-content-center">
                                <NavLink className={navLinkClass} to="/lookbook" onClick={() => toggleMenu()}>Lookbook</NavLink>
                            </div>
                            <div className="col-12 d-flex justify-content-center">
                                <NavLink className={navLinkClass} to="/contact" onClick={() => toggleMenu()}>Contact</NavLink>
                            </div>
                        </div>
                        :
                        <div id="navMenu" onMouseLeave={() => setDisplayServicesSubmenu(false)}>
                            <NavLink className={navLinkClass} to="/about">About</NavLink>
                            <div id="services-menu-item">
                                <NavLink
                                    className={`${navLinkClass} nav-services`}
                                    to="/services"
                                    onMouseEnter={() => setDisplayServicesSubmenu(true)}
                                >
                                    Services
                                </NavLink>
                                <CSSTransition in={displayServicesSubmenu} classNames="submenu-animate" timeout={1000} unmountOnExit>
                                    <div className={isHomepage ? "services-submenu-container-home" : "services-submenu-container"}>
                                        <NavLink className="submenu-link" to="/eventcalligraphy">Live Events</NavLink>
                                        <NavLink className="submenu-link" to="/engraving">Engraving</NavLink>
                                        <NavLink className="submenu-link" to="/weddingcalligraphy">Weddings</NavLink>
                                    </div>
                                </CSSTransition>
                            </div>
                            <NavLink className="big-center" exact to="/home">
                                <img src={isHomepage ? CenterLogoHome : CenterLogo} alt="Maker's Favourite Designs" className="header-center-logo" />
                            </NavLink>
                            <NavLink className={navLinkClass} to="/lookbook">Lookbook</NavLink>
                            <NavLink className={navLinkClass} to="/contact">Contact</NavLink>
                        </div>
                    }
                </nav>
            </header>
        </>
    )
}

export default withRouter(NavBar);