import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Live from '../img/new/events_home.JPG';
import Engraving from '../img/new/engraving_home.JPG';
import Wedding from '../img/new/wedding_home.JPG';
import { Link } from 'react-router-dom';

function Home() {
    return (
        <>
            <CSSTransition appear in classNames="content-fadein" timeout={1000}>
                <div>
                    <div className="homepage-content">
                        <div className='homepage-text-container container'>
                            <div className="row">
                                <div className='col-12 centered-header home-about-text'>
                                    <p>
                                        Welcome!
                                    </p>
                                    <br />
                                    <p>
                                        Maker's Favourite Designs specializes in calligraphy and lettering for live and corporate events, businesses, weddings, and personal occasions, among other things.
                                    </p>
                                    <br />
                                    <p>
                                        Calligraphy is a lost art and my goal is to rekindle love and appreciation for hand-rendered scripts and bespoke designs. So allow me to create something stunning with your words!
                                    </p>
                                    <br />
                                    <p>
                                        Thanks for visiting.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='homepage-second-content container-full'>
                        <div className="row justify-content-center">
                            <div className='col-12' style={{ paddingBottom: "3em" }}>
                                <span className="milton-font homepage-services-header">Services</span>
                                <hr className="horizontal-line" />
                                <p className="homepage-services-text">Click on each of the links below to check out all the different services offered.</p>
                            </div>
                            <div className='col-12 col-md-3'>
                                <Link to="/eventcalligraphy">
                                    <img src={Live} alt="Live Events" className="homepage-services-image" />
                                    <h4 className="milton-font homepage-services-link-text">Live Events</h4>
                                </Link>
                            </div>
                            <div className='col-12 col-md-3'>
                                <Link to="/engraving">
                                    <img src={Engraving} alt="Engraving" className="homepage-services-image" style={{ maxHeight: "503px", objectFit: "cover" }} />
                                    <h4 className="milton-font homepage-services-link-text">Engraving</h4>
                                </Link>
                            </div>
                            <div className='col-12 col-md-3'>
                                <Link to="/weddingcalligraphy">
                                    <img src={Wedding} alt="Wedding Calligraphy" className="homepage-services-image" />
                                    <h4 className="milton-font homepage-services-link-text">Wedding Calligraphy</h4>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </>
    )
}

export default Home;