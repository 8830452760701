import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useSwipeable } from 'react-swipeable';

function ImageModal(props) {
    const handlers = useSwipeable({
        onSwipedLeft: () => props.scrollPrevious(),
        onSwipedRight: () => props.scrollNext(),
    });

    return (
        <Modal show={props.show} fullscreen={true} onHide={props.handleClose} dialogClassName="image-modal-dialog" contentClassName="image-modal-content">
            <Modal.Header className="image-modal-header">
                <button className="lookbook-close-button" onClick={props.handleClose}>
                    <FontAwesomeIcon icon={faTimes} size="2x" />
                </button>
            </Modal.Header>
            <Modal.Body className="image-modal-body" onClick={props.handleClose} {...handlers}>
                <button className="lookbook-scroll-button" onClick={(e) => {
                    e.stopPropagation();
                    props.scrollPrevious();
                }}>
                    <FontAwesomeIcon icon={faChevronLeft} size="2x" />
                </button>

                <img src={props.images[props.imageIndex]} alt={props.images[props.imageIndex]} className="image-modal-image" onClick={(e) => e.stopPropagation()} />

                <button className="lookbook-scroll-button" onClick={(e) => {
                    e.stopPropagation();
                    props.scrollNext();
                }}>
                    <FontAwesomeIcon icon={faChevronRight} size="2x" />
                </button>
            </Modal.Body>
        </Modal>
    )
}

export default ImageModal;