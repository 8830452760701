import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import CardPic from '../img/card_1.jpeg'
import EngravingPic from '../img/engraving_1.jpeg'
import EnvelopePic from '../img/envelope_1.jpeg'
import QuotePic from '../img/quote_1.jpeg'
import VowsPic from '../img/vows_1.jpeg'
import GiftPic from '../img/gift_1.jpg'
import GiftPic2 from '../img/gift_2.jpg'
import ChristmasPic from '../img/christmas_1.jpg'
import ImageModal from './ImageModal';
import A1 from '../img/lookbook/A1.JPG';
import A2 from '../img/lookbook/A2.JPG';
import A3 from '../img/lookbook/A3.JPG';
import A4 from '../img/lookbook/A4.JPG';
import A5 from '../img/lookbook/A5.JPG';
import A6 from '../img/lookbook/A6.JPG';
import A7 from '../img/lookbook/A7.JPG';
import A8 from '../img/lookbook/A8.JPG';
import A9 from '../img/lookbook/A9.JPG';
import B1 from '../img/lookbook/B1.JPG';
import B2 from '../img/new/chloe.jpg';
import B3 from '../img/new/daisy.jpg';
import B4 from '../img/new/emma.jpg';
import B5 from '../img/new/knife.jpg';
import B6 from '../img/new/mimi.jpg';
import B7 from '../img/lookbook/B7.JPG';
import B8 from '../img/lookbook/B8.JPG';
import B9 from '../img/lookbook/B9.JPG';
import C1 from '../img/lookbook/C1.JPG';
import C2 from '../img/lookbook/C2.JPG';
import C3 from '../img/lookbook/C3.JPG';
import C4 from '../img/lookbook/C4.JPG';
import C5 from '../img/lookbook/C5.JPG';
import C6 from '../img/lookbook/C6.JPG';
import C7 from '../img/lookbook/C7.JPG';
import C8 from '../img/lookbook/C8.JPG';
import C9 from '../img/lookbook/C9.JPG';
import D1 from '../img/new/engraving2.JPG';


function Lookbook() {
    const [showModal, setShowModal] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [displayImages, setDisplayImages] = useState([]);

    const scrollPrevious = () => {
        selectedImageIndex === 0 ? setSelectedImageIndex(images.length - 1) : setSelectedImageIndex(selectedImageIndex - 1);
    }

    const scrollNext = () => {
        selectedImageIndex === (images.length - 1) ? setSelectedImageIndex(0) : setSelectedImageIndex(selectedImageIndex + 1);
    }
    
    const handleShow = () => setShowModal(true);
    const handleHide = () => setShowModal(false);

    const shuffle = (array) => {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex !== 0) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    const images = [
        GiftPic,
        GiftPic2,
        ChristmasPic,
        EngravingPic,
        EnvelopePic,
        QuotePic,
        VowsPic,
        CardPic,
        A1, A2, A3, A4, A5, A6, A7, A8, A9,
        B1, B2, B3, B4, B5, B6, B7, B8, B9,
        C1, C2, C3, C4, C5, C6, C7, C8, C9,
        D1,
    ];

    useEffect(() => {
        setDisplayImages(shuffle(images));
    }, []);

    const selectImage = (imageIndex) => {
        setSelectedImageIndex(imageIndex);

        handleShow();
    }

    return (
        <CSSTransition appear in classNames="content-fadein" timeout={1000}>
            <>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 lookbook-table'>
                            {displayImages.map((image, index) => (
                                <img key={image} src={image} alt="" className="lookbook-image" onClick={() => selectImage(index)} />
                            ))}
                        </div>
                    </div>
                </div>
                <ImageModal
                    show={showModal}
                    handleClose={handleHide}
                    images={displayImages}
                    imageIndex={selectedImageIndex}
                    scrollPrevious={scrollPrevious}
                    scrollNext={scrollNext}
                />
            </>
        </CSSTransition>
    )
}

export default Lookbook;