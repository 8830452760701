import React from 'react';
import InstagramIcon from '../img/instagram_icon.png';

function Footer() {
    return (
        <footer>
            <div className="App-footer">
                <div className="container-full">
                    <div className="row override-margin">
                        <div className="col-sm-12 col-md-6 footer-row">
                            © Maker's Favourite Designs 2022
                        </div>
                        <div className="col-sm-12 col-md-6 footer-row">
                            <a target="_blank" href="https://www.instagram.com/makersfavouritedesigns" className="footer-logo float-none float-md-end" rel="noopener noreferrer">
                                <img src={InstagramIcon} alt="Instagram" className="footer-instagram" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;