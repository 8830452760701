import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { NavLink } from 'react-router-dom';
import LiveEvents from '../img/new/live_events.JPG';
import Live1 from '../img/new/live1.JPG';
import Live2 from '../img/new/live2.JPG';
import Live3 from '../img/new/live3.JPG';
import Live4 from '../img/new/live4.JPG';

function EventCalligraphy() {
    return (
        <CSSTransition appear in classNames="content-fadein" timeout={1000}>
            <div>
                <div className='container' style={{ marginBottom: "4em" }}>
                    <div className='row row-less-padding'>
                        <div className='col-12'>
                            <h2 className="calligraphy-underline-header">Custom On-site Event Calligraphy</h2>
                        </div>
                        <div className='col-12' style={{ textAlign: "center" }}>
                            <p>
                                Make your event more memorable with a customized experience. Allow every single guest to feel extra special with bespoke calligraphy done right in front of them. Some examples of custom calligraphy include, but are not limited to, initials, names, special dates, name of website, Instagram handle, short poems, quotes, etc.
                            </p>
                            <p>
                                I have worked with luxury brands and feel very comfortable interacting with guests and clients.
                            </p>
                            <p>
                                So, for your next luxury event, consider me for a live on-site artist!
                            </p>
                            <NavLink to="/contact">
                                <button type="button" className="btn btn-send">GET IN TOUCH</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="container-full">
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-12" style={{ paddingTop: "0", backgroundColor: "black" }}>
                            <img src={LiveEvents} alt="Quote Calligraphy Sample" style={{ padding: 0, height: "750px", width: "80%", objectFit: "cover" }} />
                        </div>
                    </div>
                </div>
                <div className='container' style={{ marginBottom: "4em" }}>
                    <br />
                    <div className='row'>
                        <div className='col-12' style={{ paddingTop: "2em" }}>
                            <h4 className="thin-header">Past Clients & Collaborations</h4>
                            <br />
                            <p>Client work includes working for companies like Nordstrom, Bloomingdale's, Dior for Macy's, Coty, Jo Malone for Bloomingdale's, Orinithea House of Flowers, among others.</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12' style={{ paddingTop: "2em" }}>
                            <h4 className="thin-header">Custom Calligraphy Examples</h4>
                            <br />
                            <p>
                            Flowers and chocolates are nice but why not bedazzle your clients by personalizing your gifts to them? Get in touch for custom quotes or to discuss original ideas.
                            </p>
                            <NavLink to="/contact">
                                <button type="button" className="btn btn-send">GET A QUOTE</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className='container-full'>
                    <div className='row alt-background' style={{ paddingBottom: 0 }}>
                        <div className="col-sm-12 col-md-3">
                            <img src={Live1} alt="Quote Calligraphy Sample" className="sample-pic" />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <img src={Live2} alt="Quote Calligraphy Sample" className="sample-pic" />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <img src={Live3} alt="Envelope Calligraphy Sample" className="sample-pic" />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <img src={Live4} alt="Vows Calligraphy Sample" className="sample-pic" />
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}

export default EventCalligraphy;