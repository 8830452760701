import React from 'react';
import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import "react-datepicker/dist/react-datepicker.css";

function SubmitReview() {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [company, setCompany] = useState("")
    const [testimonial, setTestimonial] = useState("")
    const [validationErrors, setValidationErrors] = useState([])
    const [sent, setSent] = useState(false)

    const validateForm = () => {
        let errors = []

        if (name === "") {
            errors.push("Name is required.")
        }

        if (email === "") {
            errors.push("Email address is required.")
        } else {
            const emailRegex = /\S+@\S+\.\S+/
            if (!emailRegex.test(email)) {
                errors.push("Email address is invalid.")
            }
        }

        return errors
    }

    const onSubmit = (event) => {
        event.preventDefault()

        const formErrors = validateForm()
        setValidationErrors(formErrors)

        if (formErrors.length === 0) {
            const state = {
                name,
                email,
                company,
                testimonial
            }

            fetch("https://mfd-api-function-app-apim.azure-api.net/mfd-api/ReviewPost", {
                method: "POST",
                mode: "cors",
                body: JSON.stringify(state),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.status >= 200 && response.status < 300) {
                    setSent(true)
                } else {
                    console.log('Something went wrong.')
                }
            })
        } else {
            window.scrollTo(0, 0)
        }
    }

    return (
        <CSSTransition appear in classNames="content-fadein" timeout={1000}>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-lg-5 mobile-padding-bottom'>
                        <h2 className="thin-header">Thanks for your business!</h2>
                        <br />
                        <p>
                            Thank you for trusting me with your projects! If you would be so kind as to take some time and write a short review
                            of my work, I would really appreciate it.
                        </p>
                    </div>
                    <div className='col-lg-1'></div>
                    <div className='col-12 col-lg-6'>
                        {(validationErrors.length !== 0) ?
                            <div className="row">
                                {validationErrors.map((item, index) => (
                                    <div key={"alert-" + index} className="col-12 validation-error">{item}</div>
                                ))}
                            </div>
                            : <></>
                        }
                        <form id="review-form" onSubmit={(event) => onSubmit(event)}>
                            <div className='row'>
                                <label className="label-upper mobile-contact-label" htmlFor="name-input">
                                    Name <span className="required-asterisk">*</span>
                                </label>
                                <div className="col-12 p-0">
                                    <div className='input-group'>
                                        <div className="col-12 p-0">
                                            <input
                                                className="form-control name-input"
                                                id="first-name-input"
                                                onChange={(e) => {
                                                    setName(e.target.value)
                                                }}
                                                disabled={sent} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <label className="label-upper" htmlFor="email-input">
                                    Email <span className="required-asterisk">*</span>
                                </label>
                                <div className="col-12 p-0">
                                    <div className='input-group'>
                                        <div className="col-12 p-0">
                                            <input
                                                className="form-control"
                                                id="email-input"
                                                style={{ width: "100%" }}
                                                onChange={(e) => {
                                                    setEmail(e.target.value)
                                                }}
                                                disabled={sent} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <label className="label-upper" htmlFor="company-input">Company/Organization</label>
                                <div className="col-12 p-0">
                                    <div className='input-group'>
                                        <div className="col-12 p-0">
                                            <input
                                                className="form-control"
                                                id="company-input"
                                                style={{ width: "100%" }}
                                                onChange={(e) => {
                                                    setCompany(e.target.value)
                                                }}
                                                disabled={sent} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <label className="label-upper" htmlFor="testimonial-input">Testimonial</label>
                                <div className="col-12 p-0">
                                    <div className='input-group'>
                                        <div className="col-12 p-0">
                                            <textarea
                                                className="form-control"
                                                id="testimonial-input"
                                                size="500"
                                                style={{ width: "100%", height: "5em" }}
                                                onChange={(e) => {
                                                    setTestimonial(e.target.value)
                                                }}
                                                disabled={sent} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-12 p-0">
                                    <div className='input-group'>
                                        <button type="submit" className="btn btn-send" disabled={sent}>
                                            {(sent) ? "SENT!" : "SEND"}
                                        </button>
                                    </div>
                                </div>
                                {(sent) ?
                                    <div className="input-group">
                                        <div className="validation-success">
                                            Thanks for your feedback!
                                        </div>
                                    </div> : <></>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}

export default SubmitReview;