import React from 'react';
import PlacecardsPic from '../img/placecards_1.jpeg'
import SignPic from '../img/sign_1.jpeg'
import { CSSTransition } from 'react-transition-group';
import { NavLink } from 'react-router-dom';
import Envelope1 from '../img/new/envelope1.JPG';
import Envelope2 from '../img/new/envelope2.JPG';
import Envelope3 from '../img/new/envelope3.JPG';
import Vows from '../img/new/vows.JPG';

function WeddingCalligraphy() {
    return (
        <CSSTransition appear in classNames="content-fadein" timeout={1000}>
            <div>
                <div className='container wedding-summary'>
                    <div className='row row-less-padding'>
                        <h2 className="calligraphy-underline-header">Wedding Calligraphy</h2>
                    </div>
                    <div className='row' style={{ textAlign: "center" }}>
                        <p>It's all in the details. I offer custom calligraphy for your wedding day. This encompasses a variety of services like:
                            <ul className="bullet-list">
                                <li>Envelope Addressing</li>
                                <li>Place Cards, Table Numbers, Menus</li>
                                <li>Event Signage</li>
                                <li>Wedding Vows</li>
                            </ul>
                            Note: If you are creating your own invitations, or working with a printer or graphic designer but would
                            still like to have your names written in calligraphy, I can provide that service. You will receive a high
                            resolution digital file for your use or to send to the designers to incorporate in to your invitation.
                        </p>
                    </div>
                </div>
                <div className='container-full'>
                    <div className='row alt-background wedding-calligraphy-top justify-content-end'>
                        <h4 className="thin-header" style={{ width: "auto", paddingRight: "0.5em" }}>Envelope Calligraphy</h4>
                    </div>
                    <div className='row alt-background wedding-calligraphy-middle'>
                        <div className="col-sm-12 col-md-4">
                            <img src={Envelope1} alt="Envelope Calligraphy Sample" className="wedding-image" />
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <img src={Envelope2} alt="Envelope Calligraphy Sample" className="wedding-image" />
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <img src={Envelope3} alt="Envelope Calligraphy Sample" className="wedding-image" />
                        </div>
                    </div>
                    <div className='row alt-background'>
                        <div className='col-12 wedding-calligraphy-bottom'>
                            <p>
                                Wedding envelopes are a window into your wedding aesthetic. Custom calligraphy on your save the dates,
                                wedding invitation and thank you card envelopes will take your guests' experience to a new level.
                    </p>
                            <NavLink to="/contact">
                                <button type="button" className="btn btn-send">ORDER</button>
                            </NavLink>

                        </div>
                    </div>
                </div>
                <div className='container day-of-details'>
                    <div className='row'>
                        <div className="col-12">
                            <h4 className="thin-header float-right">Day-Of Details</h4>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-12 col-md-4 mobile-padding-bottom">
                            <img src={Vows} alt="Vows Sample" className="calligraphy-image" />
                            <h4 className="thin-header">Vows + Keepsakes</h4>
                            <br />
                            <p>
                                This is a specialized offering to create custom calligraphy vow keepsakes, special messages, or poems that will elevate the beauty of your
                                precious words to a new level. They make for a memorable gift for special occasions such as anniversaries,
                                Valentine's or any day you want to celebrate your relationship.
                            <br />
                    </p>
                        </div>
                        <div className="col-sm-12 col-md-4 mobile-padding-bottom">
                            <img src={PlacecardsPic} alt="Place Cards Sample" className="calligraphy-image" />
                            <h4 className="thin-header">Place Cards</h4>
                            <br />
                            <p>
                                Make your guests feel special by addressing their names in calligraphy, using place cards. In addition to paper,
                                I can work with other interesting elements - acrylics, marble, leaves, agate, shells or ceramics - that could
                                complement your wedding decor, for table assignment, seat assignments and meal choice.
                    </p>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <img src={SignPic} alt="Signage Sample" className="calligraphy-image" />
                            <h4 className="thin-header">Signage</h4>
                            <br />
                            <p>
                                I offer hand-lettered table numbers and welcome signs to tie in all the print details for your special day. This
                                will be customized to reflect the mood and style of the wedding and venue.
                    </p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12' align="center">
                            <NavLink to="/contact">
                                <button type="button" className="btn btn-send">ORDER</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}

export default WeddingCalligraphy;