import React from 'react';
import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

function Contact() {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [interestedIn, setInterestedIn] = useState([])
    const [eventDate, setEventDate] = useState(null)
    const [message, setMessage] = useState("")
    const [source, setSource] = useState("")
    const [validationErrors, setValidationErrors] = useState([])
    const [sent, setSent] = useState(false)

    const validateForm = () => {
        let errors = []

        if (firstName === "" || lastName === "") {
            errors.push("Both first and last name are required.")
        }

        if (email === "") {
            errors.push("Email address is required.")
        } else {
            const emailRegex = /\S+@\S+\.\S+/
            if (!emailRegex.test(email)) {
                errors.push("Email address is invalid.")
            }
        }

        if (interestedIn.length === 0) {
            errors.push("Please select at least one item you are interested in.")
        }

        return errors
    }

    const onCheckboxChange = (e, checkboxName) => {
        let newArray = []

        if (e.target.checked) {
            newArray = interestedIn
            newArray.push(checkboxName)
        } else {
            newArray = interestedIn.filter((value) => {
                return value !== checkboxName
            })
        }

        setInterestedIn(newArray)
    }

    const getDateString = (date) => {
        const year = date.getFullYear()
        const month = ("0" + (date.getMonth() + 1)).slice(-2) //months are 0-based
        const day = ("0" + date.getDate()).slice(-2)

        return `${year}-${month}-${day}`
    }

    const onSubmit = (event) => {
        event.preventDefault()

        const formErrors = validateForm()
        setValidationErrors(formErrors)

        if (formErrors.length === 0) {
            setSent(true)

            const state = {
                firstName,
                lastName,
                email,
                interestedIn: interestedIn.join(", "),
                eventDate: eventDate ? getDateString(eventDate) : "",
                message,
                source
            }

            fetch("https://mfd-api-function-app-apim.azure-api.net/mfd-api/EmailPost", {
                method: "POST",
                mode: "cors",
                body: JSON.stringify(state),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.status < 200 || response.status >= 300) {
                    setSent(false)
                    console.log('Something went wrong.')
                }
            })
        } else {
            window.scrollTo(0, 0)
        }
    }

    return (
        <CSSTransition appear in classNames="content-fadein" timeout={1000}>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-lg-5 mobile-padding-bottom'>
                        <h2 className="thin-header">SAY HI</h2>
                        <br />
                        <p>
                            Want to work together?
                            Whether you need custom designed wedding invitations, modern calligraphy services, or are seeking live event calligraphy as an activation,
                            I’d love to learn more about your vision and project.
                    </p>
                        <p>
                            For styled shoots, logo design, or other creative collaborations, please connect with me directly at makersfavouritedesigns@gmail.com.
                    </p>
                        <p>
                            (978)-436-0872
                    </p>
                    </div>
                    <div className='col-lg-1'></div>
                    <div className='col-12 col-lg-6'>
                        {(validationErrors.length !== 0) ?
                            <div className="row">
                                {validationErrors.map((item, index) => (
                                    <div key={"alert-" + index} className="col-12 validation-error">{item}</div>
                                ))}
                            </div>
                            : <></>
                        }
                        <form id="contact-form" onSubmit={(event) => onSubmit(event)}>
                            <div className='row'>
                                <label className="label-upper mobile-contact-label" htmlFor="first-name-input">
                                    Name <span className="required-asterisk">*</span>
                                </label>
                                <div className="col-12 p-0">
                                    <div className='input-group'>
                                        <div className="col-12 col-md-6 p-0">
                                            <input
                                                className="form-control firstname-input"
                                                id="first-name-input"
                                                onChange={(e) => {
                                                    setFirstName(e.target.value)
                                                }}
                                                disabled={sent} />
                                            <div className="mini-label mobile-padding-bottom-less">First Name</div>
                                        </div>
                                        <div className="col-12 col-md-6 p-0">
                                            <input
                                                className="form-control"
                                                id="last-name-input"
                                                onChange={(e) => {
                                                    setLastName(e.target.value)
                                                }}
                                                disabled={sent} />
                                                <span className="mini-label">Last Name</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <label className="label-upper" htmlFor="email-input">
                                    Email <span className="required-asterisk">*</span>
                                </label>
                                <div className="col-12 p-0">
                                    <div className='input-group'>
                                        <div className="col-12 p-0">
                                            <input
                                                className="form-control"
                                                id="email-input"
                                                style={{ width: "100%" }}
                                                onChange={(e) => {
                                                    setEmail(e.target.value)
                                                }}
                                                disabled={sent} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <label className="label-upper" style={{ paddingBottom: "10px" }}>
                                    Interested In <span className="required-asterisk">*</span>
                                </label>
                                <div className='form-check input-group'>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="chk-on-site"
                                        onChange={(e) => {
                                            onCheckboxChange(e, "On-site Event Calligraphy")
                                        }}
                                        disabled={sent} />
                                    <label className="form-check-label mini-label" htmlFor="chk-on-site">On-site Event Calligraphy</label>
                                </div>
                                <div className='form-check input-group'>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="chk-wedding-stationery"
                                        onChange={(e) => {
                                            onCheckboxChange(e, "Wedding Stationery")
                                        }}
                                        disabled={sent} />
                                    <label className="form-check-label mini-label" htmlFor="chk-wedding-stationery">Wedding Stationery</label>
                                </div>
                                <div className='form-check input-group'>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="chk-envelopes"
                                        onChange={(e) => {
                                            onCheckboxChange(e, "Envelope Calligraphy")
                                        }}
                                        disabled={sent} />
                                    <label className="form-check-label mini-label" htmlFor="chk-envelopes">Envelope Calligraphy</label>
                                </div>
                                <div className='form-check input-group'>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="chk-engraving"
                                        onChange={(e) => {
                                            onCheckboxChange(e, "Engraving")
                                        }}
                                        disabled={sent} />
                                    <label className="form-check-label mini-label" htmlFor="chk-engraving">Engraving</label>
                                </div>
                                <div className='form-check input-group'>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="chk-other"
                                        onChange={(e) => {
                                            onCheckboxChange(e, "Other")
                                        }}
                                        disabled={sent} />
                                    <label className="form-check-label mini-label" htmlFor="chk-other">Other</label>
                                </div>
                            </div>
                            <div className='row'>
                                <label className="label-upper" htmlFor="event-date">Event Date</label>
                                <div className="col-12 p-0">
                                    <div className='input-group'>
                                        <div className="col-12 p-0">
                                            <DatePicker
                                                selected={eventDate}
                                                className="form-control"
                                                type="date"
                                                id="event-date"
                                                placeholderText="mm/dd/yyyy"
                                                onChange={(date) => {
                                                    setEventDate(date)
                                                }}
                                                disabled={sent} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <label className="label-upper" htmlFor="message-input">Message</label>
                                <div className="col-12 p-0">
                                    <div className='input-group'>
                                        <div className="col-12 p-0">
                                            <textarea
                                                className="form-control"
                                                id="message-input"
                                                size="500"
                                                style={{ width: "100%", height: "5em" }}
                                                onChange={(e) => {
                                                    setMessage(e.target.value)
                                                }}
                                                disabled={sent} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <label className="label-upper" htmlFor="refer-input">How did you hear about me?</label>
                                <div className="col-12 p-0">
                                    <div className='input-group'>
                                        <div className="col-12 p-0">
                                            <input
                                                className="form-control"
                                                id="refer-input"
                                                style={{ width: "100%" }}
                                                onChange={(e) => {
                                                    setSource(e.target.value)
                                                }}
                                                disabled={sent} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-12 p-0">
                                    <div className='input-group'>
                                        <button type="submit" className="btn btn-send" disabled={sent}>
                                            {(sent) ? "SENT!" : "SEND"}
                                        </button>
                                    </div>
                                </div>
                                {(sent) ?
                                    <div className="input-group">
                                        <div className="validation-success">
                                            Thanks for reaching out! You should hear back shortly.
                                        </div>
                                    </div> : <></>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}

export default Contact;