import React from 'react';
import CardPic from '../img/card_1.jpeg'
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import LiveEvents from '../img/new/services_events.JPG';
import Engraving from '../img/new/candle.jpg';

function Services() {
    return (
        <CSSTransition appear in classNames="content-fadein" timeout={1000}>
            <div className="container">
                <div className='row' style={{ paddingBottom: "7em" }}>
                    <div className='col-sm-12 col-md-6 mobile-padding-bottom order-sm-2 order-md-1 services-header'>
                        <Link to="/eventcalligraphy">
                            <h5 className="thin-header centered-header">Promote in style with</h5>
                            <h2 className="calligraphy-underline-header">Live Events</h2>
                        </Link>
                    </div>
                    <div className='col-sm-12 col-md-6 order-sm-1 order-md-2 mobile-padding-bottom' style={{ textAlign: "center" }}>
                        <Link to="/eventcalligraphy">
                            <img src={LiveEvents} alt="Live Events" className="services-image" />
                        </Link>
                    </div>
                </div>
                <div className='row' style={{ paddingBottom: "7em" }}>
                    <div className='col-sm-12 col-md-6 order-2 order-md-1 mobile-padding-bottom' style={{ textAlign: "center" }}>
                        <Link to="/engraving">
                            <img src={Engraving} alt="Engraving" className="services-image" />
                        </Link>
                    </div>
                    <div className='col-sm-12 col-md-6 order-1 order-md-2 mobile-padding-bottom services-header'>
                        <Link to="/engraving">
                            <h5 className="thin-header centered-header">Customize your keepsakes with</h5>
                            <h2 className="calligraphy-underline-header">Engraving</h2>
                        </Link>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12 col-md-6 mobile-padding-bottom order-sm-2 order-md-1 services-header'>
                        <Link to="/weddingcalligraphy" className="wedding-calligraphy-link-text">
                            <h5 className="thin-header centered-header">Make your day special with</h5>
                            <h2 className="calligraphy-underline-header">Wedding Calligraphy</h2>
                        </Link>
                    </div>
                    <div className='col-sm-12 col-md-6 order-sm-1 order-md-2 mobile-padding-bottom' style={{ textAlign: "center" }}>
                        <Link to="/weddingcalligraphy">
                            <img src={CardPic} alt="Wedding Calligraphy" className="services-image" />
                        </Link>
                    </div>
                </div>
            </div>
        </CSSTransition >
    )
}

export default Services;