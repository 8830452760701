import React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import ScrollToTop from './util/ScrollToTop';
import './App.scss';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from './components/Home';
import Services from './components/Services';
import About from './components/About';
import Lookbook from './components/Lookbook';
import Contact from './components/Contact';
import EventCalligraphy from './components/EventCalligraphy';
import WeddingCalligraphy from './components/WeddingCalligraphy';
import Engraving from './components/Engraving';
import SubmitReview from './components/SubmitReview';
import HomepageImage from './img/homepage_5.jpg';

const App = () => {
  const location = useLocation();

  const isHome = location.pathname === "/home";

  return (
    <div className="App-background" style={isHome ? { backgroundImage: "url(" + HomepageImage + ")" } : {}}>
      <NavBar />
      <div className="App-content" style={isHome ? { paddingBottom: 0 } : { backgroundColor: "#FFFFFF" }}>
        <ScrollToTop>
          <Switch>
            {/* <Route exact path="/" component={UnderConstruction} /> */}
            <Route path="/home" component={Home} />
            <Route path="/lookbook" component={Lookbook} />
            <Route path="/services" component={Services} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/eventcalligraphy" component={EventCalligraphy} />
            <Route path="/weddingcalligraphy" component={WeddingCalligraphy} />
            <Route path="/engraving" component={Engraving} />
            <Route path="/review" component={SubmitReview} />
            <Redirect to="/home" />
          </Switch>
        </ScrollToTop>
      </div>
      <Footer />
    </div>
  );
}

export default App;
