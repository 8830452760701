import React from 'react';
import { CSSTransition } from 'react-transition-group';
import EstherPic from '../img/esther_2.jpg';

function About() {
    return (
        <CSSTransition appear in classNames="content-fadein" timeout={1000}>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-lg-6 esther-about-pic-container'>
                        <img src={EstherPic} alt="Esther" className="esther-about-pic" />
                    </div>
                    <div className='col-12 col-lg-6 esther-about-text'>
                        <p className='milton-font'>
                            Hello!
                        </p>
                        <p>
                            I'm Esther and I live in the heart of Massachusetts - Boston. I'm originally from the bustling city of Mumbai and I bring that energy along with me everywhere I go. When I'm not working as a Senior Engineer at my day job, I'm moonlighting as a Calligrapher, Engraver and Lettering Artist.
                        </p>
                        <p>
                            When I was very young, my dear mum instilled in me the discipline of good handwriting and I'm so grateful for that. Ever since I've had a deep appreciation for beautiful script and handwritten communication.
                        </p>
                        <p>
                            I'm known for my timeless and classic calligraphy scripts but I'm also proficient in modern and romantic style scripts that are popular with chic luxury brands.
                        </p>
                        <p>
                            At Maker's Favourite Designs, my clients consist of corporate firms, brands, and individuals. I've had the honour of working with clients like Bloomingdale's, Jo Malone London, Nordstrom, COTY Inc., Ornithea, and more.
                        </p>
                        <p>
                            I have spent years honing my skills and pride myself in delivering high-quality products so that you and your clients can have a seamless luxury experience.
                        </p>
                        <p>
                            My goal here at Maker's Favourite Designs is to rekindle love and appreciation for hand-rendered scripts and bespoke designs. So, allow me to create something stunning with your words!
                        </p>
                        <br />
                        <p className='milton-font'>
                            <span style={{ marginBottom: "-100px" }}>Love,</span><br />
                            Esther
                        </p>
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}

export default About;