import React from 'react';
import EngravingPic from '../img/engraving_1.jpeg'
import { CSSTransition } from 'react-transition-group';
import { NavLink } from 'react-router-dom';
import Step1 from '../img/engraving_steps_1.jpeg';
import Step2 from '../img/engraving_steps_2.jpeg';
import Step3 from '../img/engraving_steps_3.jpeg';
import Step4Alt from '../img/engraving_steps_4a.jpeg';
import InStudioEngraving from '../img/new/in_studio_engraving.JPG';
import Surfaces from '../img/new/surfaces2.JPG';
import Engraving1 from '../img/new/engraving1.JPG';
import Engraving3 from '../img/new/engraving3.JPG';
import Engraving4 from '../img/new/engraving4.JPG';
import Engraving5 from '../img/new/engraving5.JPG';

function Engraving() {
    return (
        <CSSTransition appear in classNames="content-fadein" timeout={1000}>
            <div>
                <div className='container' style={{ marginBottom: "4em" }}>
                    <div className='row row-less-padding'>
                        <div className='col-12'>
                            <h2 className="calligraphy-underline-header">Engraving &amp; Boston Event Offerings</h2>
                        </div>
                        <div className='col-12' style={{ textAlign: "center" }}>
                            <p>
                                Live, on-site calligraphy events, personalization, gifting services for special events + retailers.
                                </p>
                        </div>
                    </div>
                    <div className='row' style={{ paddingTop: "6em" }}>
                        <div className='col-sm-12 col-md-6 mobile-padding-bottom order-sm-2 order-md-1'>
                            <h4 className="thin-header">Custom Hand Engraving</h4>
                            <br />
                            <p>
                                While inks and paints on paper or other surfaces can yield beautiful results, sometimes you just need
                                something more enduring and luxurious to take your gifting to a new level.
                                Think - custom hand or glass engraving.
                            </p>
                            <br />
                            <h4 className="thin-header">Corporate Engraving</h4>
                            <br />
                            <p>
                                I offer on-site engraving events in the Boston and Greater Boston area for vendors and retailers looking for
                                gifts that are chic and long-lasting for their clientele. This can be a great opportunity to get your clients
                                to interact safely and get personalized takeaways.
                                Fragrance bottles, candles, wine & liquor bottles, skincare bottles, granite tiles and serving trays are some
                                of the surfaces that I can engrave in your store or event-space.
                                Additionally, engraved messages can be filled with gold or silver finishes to add panache.

                                Contact me for more details or to schedule an on-site event!
                            </p>
                            <br />
                            <NavLink to="/contact">
                                <button type="button" className="btn btn-send">GET IN TOUCH</button>
                            </NavLink>
                        </div>
                        <div className='col-sm-12 col-md-6 order-sm-1 order-md-2 mobile-padding-bottom'>
                            <img src={Engraving4} alt="Engraving Sample" className="calligraphy-image" />
                        </div>
                    </div>
                    <br />
                </div>
                <div className='container-full' style={{ marginBottom: "4em" }}>
                    <div className='row alt-background process-steps-row'>
                        <div className="col-12">
                            <h4 className="thin-header process-steps-header">Curious About the Process?</h4>
                            <br />
                        </div>
                        <div className="col-sm-12 col-md-3 engraving-step">
                            <img src={Step1} alt="Engraving Step 1" className="engraving-steps-image" />
                            <p>
                                1. Freehand engraving is done on the non-textured surface with a portable micro drill. This could be a name or a short message.
                            </p>
                        </div>
                        <div className="col-sm-12 col-md-3 engraving-step">
                            <img src={Step2} alt="Engraving Step 2" className="engraving-steps-image" />
                            <p>
                                2. Dust produced from the drilling is brushed away to clean and protect the product from any damage.
                            </p>
                        </div>
                        <div className="col-sm-12 col-md-3 engraving-step">
                            <img src={Step3} alt="Engraving Step 3" className="engraving-steps-image" />
                            <p>
                                3. Gold, rose gold or silver filling can be used. Suitable for glass and acrylic surfaces that will not be heavily used.
                            </p>
                        </div>
                        <div className="col-sm-12 col-md-3 engraving-step">
                            <img src={Step4Alt} alt="Engraving Step 4" className="engraving-steps-image" />
                            <p>
                                4. The finished product is classy, permanently engraved, and personalized to last.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='container' style={{ marginBottom: "4em" }}>
                    <div className='row'>
                        <div className='col-sm-12 col-md-6 mobile-padding-bottom'>
                            <h4 className="thin-header">In-Studio Engraving</h4>
                            <br />
                            <p>
                                Be it a milestone occasion or celebration of a special achievement, engraving can add a unique touch to your gifts.
                                Allow me the opportunity to make your gift to family, friends or clients a memorable one. Commissions can be completed
                                in advance. Feel free to contact me for availability and a custom quote.
                            </p>
                            <br />
                            <NavLink to="/contact">
                                <button type="button" className="btn btn-send">GET IN TOUCH</button>
                            </NavLink>
                        </div>
                        <div className='col-sm-12 col-md-6 mobile-padding-bottom'>
                            <img src={InStudioEngraving} alt="Engraving Sample" className="calligraphy-image" />
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-sm-12 col-md-6 mobile-padding-bottom'>
                            <img src={Surfaces} alt="Engraving Sample" className="calligraphy-image" />
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <h4 className="thin-header">Popular Surfaces That Can Be Custom-Engraved</h4>
                            <br />
                            <p>
                                Smooth, non-textured glass, metal, ceramics and some plastics make for ideal canidates for engraving.
                                <br /><br />
                                <div className='row'>
                                    <div className='col-12 col-md-6' style={{ marginBottom: "-16px" }}>
                                        <ul>
                                        <li>Fragrance Bottles</li>
                                            <li>Candle Jars</li>
                                            <li>Candle Lids - Glass or Metal</li>
                                            <li>Liquor and Wine Bottles</li>
                                            <li>Acrylic Ornaments</li>
                                            <li>Stainless Steel Bottles</li>
                                            <li>Insulated Cups, Vases</li>
                                            <li>Knives</li>
                                        </ul>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <ul>
                                            <li>Marble Coasters</li>
                                            <li>Cosmetics</li>
                                            <li>Glass Ornaments</li>
                                            <li>Skincare Bottles</li>
                                            <li>Metallic Mugs</li>
                                            <li>Granite Tiles</li>
                                            <li>Metal Lipstick Covers</li>
                                            <li>Cosmetic Mirrors</li>
                                        </ul>
                                    </div>
                                </div>
                            </p>
                            <h4 className="thin-header">Surfaces That Cannot Be Engraved</h4>
                            <br />
                            <p>
                                Any pressurized bottles like champagne or sparkling wine cannot and should not be engraved to keep the bottles from exploding. 
                                For pressurized bottles, I offer painting or doing calligraphy with a permanent paint pen. 
                                Metals like titanium, or beveled and uneven surfaces cannot be hand-engraved.
                            </p>
                            <p>
                                You can always reach out if you are unsure of surfaces that can or cannot be engraved.
                            </p>
                            <br />
                            <NavLink to="/contact">
                                <button type="button" className="btn btn-send">CONTACT ME FOR A QUOTE</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className='container-full'>
                    <div className='row alt-background' style={{ paddingBottom: 0 }}>
                        <div className="col-sm-12 col-md-3">
                            <img src={Engraving1} alt="Quote Calligraphy Sample" className="sample-pic" style={{ height: "100%", objectFit: "cover" }} />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <img src={Engraving5} alt="Quote Calligraphy Sample" className="sample-pic" />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <img src={Engraving3} alt="Envelope Calligraphy Sample" className="sample-pic" />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <img src={EngravingPic} alt="Vows Calligraphy Sample" className="sample-pic" style={{ height: "100%", objectFit: "cover" }} />
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}

export default Engraving;